<template>
	<div>
		
		
		<div id="header">
			
			<div class="header-mobile mobile">
				<img :src="project.logo" />
				<div id="connect_wallet">
					<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
					<div v-else>
						{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
						Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
					</div>
				</div>
			</div>
			<div style="width: 320px; text-align: left; padding: 17px;"><img :src="project.logo" class="desktop" /></div>
			<div id="connect_wallet"  class="desktop">
				<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
				<div v-else>
					{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
					Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
				</div>
			</div>
			<div class="advertisement">
				<a href="https://effe2.xyz/" target="_blank" style="color: #fff; text-decoration: none;"> 
					Powered by Effe²<br/>
					<span>Free tools for NFT projects on Solana</span>
				</a>
			</div>
		</div>
		
		<div class="mint-builder-image">
			<br/>
			<img :src="new_image" width="500px" height="500px" /><br/>
			<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')" style="margin-left: calc(50% - 80px); margin-top: 20px;">Connect wallet</div>
		</div>
		<div id="raffles" class="mint-category-container">
			
			<div class="raffle-container" v-for="(category, category_name) in layers" :key="category_name">
				<h2 class="mint-category-name" @click="update_current_category(category_name)" :id="category_name_to_id(category_name)">
					{{ category_name }} 
					
					<div style="float: right; position: relative; top: -5px;">
						<span class="mint-badge mint-badge-optional" v-show="category.options.mandatory === false">Optional</span>
						<span class="mint-badge mint-badge-mandatory" v-show="category.options.mandatory === true">Mandatory</span>
					</div>
				</h2>
				<div class="mint-category-disabled" v-show="category_name == 'teeth' && final_layers.emotions != '/storage/layers/minis/emotions/Default.png'">Only available with "default" emotion !</div>
				<div class="mint-category-disabled" v-show="category_name == 'lip pierce' && final_layers.emotions != '/storage/layers/minis/emotions/Default.png'">Only available with "default" emotion !</div>
				
				<div class="mint-category" style="display: flex;" v-show="current_category == category_name || steps_completed === false">
					
					<div class="mint-img" :class="{'mint-img-selected': final_layers[category_name] == 'none'}" v-show="category.options.mandatory === false">
						<div @click="update_layer(category_name, 'none')" style="wudth: 197px; height: 197px;"></div>
						None
					</div>
						
					<div v-for="(layer, layer_name) in category.layers" :key="layer_name">
						
						<template v-if="layer.options.false == undefined">
							<div class="mint-img" :class="{'mint-img-selected': final_layers[category_name] == layer.src, 'mint-grayscale': attribute_disabled(category_name, layer)}">
								<span class="mint-badge mint-badge-optional mint-badge-supply">Minted: 0 / {{ layer.options.supply }}</span>
								<img :src="'https://back.the-cocktail-crew.com/'+layer.src" @click="update_layer(category_name, layer.src)" /><br/>
								{{ layer_name }}
								<span class="mint-badge mint-badge-optional" v-if="layer.options.price == 0">Free</span>
								<span class="mint-badge mint-badge-mandatory" v-if="layer.options.price > 0">{{ layer.options.price }} SOL</span>
							</div>
						</template>
						<template v-else>
							<div style="width: 210px;"></div>
						</template>
					</div>
						
				</div>
			</div>
			
		</div>
		
	</div>
</template>


<script>


import { mint, check_escrow, fetch_all_escrow_wl_limited, fetch_all_escrow_wl_unlimited } from '@/libs/rustProgram';
import {TweenMax, Quart} from 'gsap';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {getProject} from '@/libs/project';
import $ from 'jquery';
import { establishConnection, getTokenAccountForTokenAndOwner, getTokenAccountBalance, confirmTransaction, getNftOwned, getNftMetadata } from "@/libs/solanaConnection";
import { PublicKey } from '@solana/web3.js';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

var connection = null;


export default {
	name: 'Builder',
	components: {},

	data: function () {

		return {
			
			token_balance: 0,
			project: {},
			layers:  [],
			categories:  [],
			current_category: 'backgrounds',
			final_layers: {
				backgrounds: "/storage/layers/minis/backgrounds/Gold 1.png",
				backhand: "/storage/layers/minis/backhand/Ball.png",
				clothes: 'none',
				emotions: "/storage/layers/minis/emotions/Default.png",
				eyes: 'none',
				glass: "/storage/layers/minis/glass/Milkshake.png",
				head: 'none',
				mustach: 'none',
				teeth: 'none',
				ring: 'none',
				tatoo: 'none',
				'lip pierce': 'none',
			},
			new_image: '/assets/images/default_nft.png',
			steps_completed: false,
			steps_completed_per_category: {
				backgrounds: false,
				backhand: false,
				clothes: false,
				emotions: false,
				eyes: false,
				glass: false,
				head: false,
				mustach: false,
				teeth: false,
				ring: false,
				tatoo: false,
				'lip pierce': false,
			},
		}
	},
	
	computed: {
		
	},

	created: function(){
		var $this = this;
		
		// $this.check_escrow(true);
		
		$this.get_layers();
		
		this.$root.$on('projectSelected', function() {
		
			$this.project = $this.$root.project;
		
		});
		
		this.$root.$on('walletConnected', function() {
			
			$this.get_token_balance();
			
			// $this.get_phase_state();
			
		});
		
		this.$root.get_project_ready = true;
		
		// this.update_layer('backgrounds', '/storage/layers/minis/backgrounds/Gold 1.png');
	},
	mounted: function(){
	
	},

	methods: {
	
		category_name_to_id: function(category) {
				
			return category.replaceAll(' ', '_').toLowerCase();
		},
		
		attribute_disabled: function(category_name, layer) {
			
			if(category_name == 'teeth') {
				
				if(this.final_layers.emotions != '/storage/layers/minis/emotions/Default.png')
					return true;
			}
			
			if(category_name == 'lip pierce') {
				
				if(this.final_layers.emotions != '/storage/layers/minis/emotions/Default.png')
					return true;
			}
			
			return false;
		},
	
		update_current_category: function(category) {
			
			if(this.current_category == category) {
				
				this.current_category = false;
				return;
			}
			
			this.current_category = category;
		},
	
		get_layers: async function() {
		
			var layers = await axios.get("https://back.the-cocktail-crew.com/mint/get-layers");
			
			this.layers = layers.data.layers;
			this.categories = layers.data.categories;
			
			this.organize_layers();
			
		},
		
		organize_layers: function() {
			
			// console.log(this.layers);
			
			for(var category in this.layers) {
			
				// console.log(this.layers[category]);
				
				// console.log(this.layers[category].layers);
				var attributes = Object.keys(this.layers[category].layers).length;
				
				if(this.layers[category].options.mandatory === false) {
				
					attributes++;
				}
					
				var modulo = attributes % 4;
				
				if(modulo == 0)
					continue;
				
				for(var i = modulo; i < 4; i++) {
					
					// this.layers[category].layers.push({});
					this.layers[category].layers['zzz'+i] = {options:{false: true}, src: ''};
				}
			}
		},
	
		get_token_balance: async function() {
			
			if(!connection)
				connection = await establishConnection();
			
			var buyer_token_account = await getTokenAccountForTokenAndOwner(this.project.token_address, getWalletAddress());
			
			var balance = await getTokenAccountBalance(new PublicKey(buyer_token_account));
			
			this.token_balance = balance;
		},
		
		update_layer: async function(category, value) {
			
			this.final_layers[category] = value;
			this.new_image = '/assets/ajax-loading-nft.gif';
			
			if(category == 'teeth' || category == 'emotions') {
				
				if(this.final_layers.emotions != '/storage/layers/minis/emotions/Default.png') {
					
					this.final_layers['teeth'] = 'none';
				}
			}
			
			if(category == 'lip pierce' || category == 'emotions') {
				
				if(this.final_layers.emotions != '/storage/layers/minis/emotions/Default.png') {
					
					this.final_layers['lip pierce'] = 'none';
				}
			}
			
			
			var new_image = await axios.post('https://back.the-cocktail-crew.com/mint/update-tmp-image', {layers: this.final_layers});
			
			this.new_image = 'https://back.the-cocktail-crew.com/'+new_image.data.link;
			
			if(category == 'lip pierce') {
				
				this.steps_completed = true;
			}
			
			if(this.steps_completed_per_category[category] === true)
				return;
			
			// go to next category
			var next = false;
			for(var category_tmp of this.categories) {
			
				
				if(next === true && this.steps_completed === false) {
					
					var anchor = this.category_name_to_id(category_tmp);
					
					// expend new category
					this.update_current_category(category_tmp);
					
					// this.$root.$emit('openLoader');
					
					var $this = this;
					
					setTimeout(function() {
					
						// $this.$root.$emit('closeLoader');
						
						var top = $("#"+anchor).offset().top - 50;
					
						$([document.documentElement, document.body]).animate({
							scrollTop: top
						}, 500);
					}, 500)
					
					
					break;
				}
				
				if(category_tmp == category)
					next = true;
			}
			
			this.steps_completed_per_category[category] = true;
		}
		
		
	
		
		
	},
	watch:{}
}
</script>



